import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

import gsap from "gsap"

const CustomCursor = lazy(() => import('./components/intro/customcursor'))
const App = lazy(() => import('./App'))

const renderLoader = () => <p></p>;

gsap.ticker.fps(120)

document.addEventListener('contextmenu', event => event.preventDefault());

const AppStarting = () => (
  <Suspense fallback={renderLoader()}>
    <CustomCursor />
    <App />
  </Suspense>
)

ReactDOM.render(
  <BrowserRouter>
    {AppStarting()}
  </BrowserRouter>,
  document.getElementById("root")
);

reportWebVitals();
